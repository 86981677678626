<template>
  <div id="fill-main" class="layout--full-page">
    <register-nav-bar />
    <div id="page-main-container" class="flex w-full bg-img vx-row no-gutter items-center justify-center mt-base"
      :style="{ backgroundImage: `url('https://cdn.redgirasol.com/green/FONDOS/fondo-redgirasol-1.png')` }">
      <div class="vx-col w-full">
        <div class="content-wrapper">
          <div class="router-view">
            <div class="router-content">
              <div class="content-area__content">
                <transition :name="routerTransition" mode="out-in">
                  <router-view @changeRouteTitle="changeRouteTitle" />
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer :year="currentYear()" :footer_text="footerText" />
  </div>
</template>
  
<script>
import AppFooter from '../components/AppFooter.vue'
import '@assets/css/applicants_investors.css'
import RegisterNavBar from "@/layouts/public/RegisterNavBar";
export default {
  components: {
    AppFooter,
    RegisterNavBar
  },
  data() {
    return {
      routerTransition: 'zoom-fade',
      footerText: "",
    }
  },
  async mounted() {
    // await this.setFooterText();
  },
  watch: {
    '$route'() {
      this.routeTitle = this.$route.meta.pageTitle;
    },
  },
  methods: {
    changeRouteTitle(title) {
      this.routeTitle = title
    },
    currentYear() {
      return new Date().getFullYear();
    },
    async setFooterText() {
      await axios.get('/api/public-footer-text').then((response) => {
        this.footerText = response.data;
      });
    },
  },
}

</script>
  
<style lang="scss">
#page-main-container {
  background-size: cover;
  min-height: 100vh;
}
</style>
  