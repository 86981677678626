<template>
  <div class="relative">
    <div class="vx-navbar-wrapper navbar-full public-navbar p-0">
      <vs-navbar :id="isLogged ? 'register-navbar':''" class="vx-navbar navbar-custom navbar-skelton" :color="navbarColorLocal" :class="textColor">
        <div class="py-3" @click="goToMainPage" style="cursor:pointer">
          <img :src="getLogoUrl" alt="Logo" class="top-nav-logo">
        </div>
        <vs-button v-if="isLogged" @click.stop="executeAppLogout()" type="border" class="float-right">Cerrar sesión</vs-button>
      </vs-navbar>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegisterNavBar",
  computed: {
    navbarColorLocal () {
      return 'rgb(59,58,62)';
    },
    textColor () {
      return 'text-white';
    },
    getLogoUrl: function() {
      return this.$data.StorageURL + "/green/LOGOS/redgirasol.png"
    },
  },
  methods: {
    goToMainPage(){
      window.location = this.WebSiteUrl;
    }
  }
}
</script>

<style>
.public-navbar{
  position: fixed;
}
.top-nav-logo{
  width: 150px;
}

#register-navbar .vs-con-items {
  justify-content: space-between !important;
}
</style>